import '/assets/styles/sections/mobile-nav.scss';
import { useMemo } from 'react';
import classNames from 'classnames';

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { useApplication } from '/utilities/application';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const MobileNav = () => {
	const { isApplication } = useApplication();
	const { activeRoute } = useNavigator();
	const { mobile } = useBreakpoints();
	const { openModal, closeModal, modalData } = useModals();
	const { loggedIn, initialized: authInitialized } = useAuth();

	const activeModalKeys = useMemo(() => {
		return modalData.reduce((map, layer, nth) => {
			if(layer?.component?.name) {
				return {
					...map,
					[layer.component.name]: nth + 1,
				}
			}
			return map;
		}, {});
	}, [modalData])
 
	const accountItemActive = useMemo(() => (activeModalKeys['auth'] || activeModalKeys['mobileMenu']), [activeModalKeys]);

	const disableNavLinks = useMemo(() => activeModalKeys['walletQr'] || accountItemActive, [accountItemActive, activeModalKeys])

	const hide = useMemo(() => {
		return !mobile || activeRoute?.layout === 'checkout';
	}, [activeRoute, mobile])

	const toggleModal = (key, options) => {
		if(activeModalKeys[key]) {
			closeModal(activeModalKeys[key]);
		}
		else {
			openModal(key, options);
		}
	}

	if(hide) { return null; }

	return (
		<nav className="section mobilenav">
			<div className="wrapper mobilenav-wrap">
				<Link
					navLink
					className={classNames('mobilenav-link', { inactive: disableNavLinks })}
					onClick={() => {
						closeModal();
					}}
					href="home">
					<Icon
						name="home"
						className="link-icon" />
					<strong>Ana Sayfa</strong>
				</Link>
				{(isApplication && (loggedIn || !authInitialized)) ?
					<Link
						navLink
						exact={false}
						className={classNames('mobilenav-link', { inactive: disableNavLinks })}
						onClick={() => {
							closeModal();
						}}
						href="events">
						<Icon
							name="stage"
							className="link-icon" />
						<strong>Etkinlikler</strong>
					</Link>
					:
					<Link
						navLink
						exact={false}
						className={classNames('mobilenav-link', { inactive: disableNavLinks })}
						onClick={() => {
							closeModal();
						}}
						href="contact">
						<Icon
							name="phone"
							className="link-icon" />
						<strong>İletişim</strong>
					</Link>
				}
				{(isApplication && (loggedIn || !authInitialized)) ?
					<button
						disabled={!authInitialized}
						type="button"
						onClick={() => {
							toggleModal('walletQr');
						}}
						className={classNames('mobilenav-link', 'highlight', { active: activeModalKeys['walletQr'] })}>
						<Icon
							name="wallet"
							className="link-icon" />
						<strong>IF Pay</strong>
					</button>
					:
					<Link
						navLink
						exact={false}
						className={classNames('mobilenav-link', 'highlight', { inactive: disableNavLinks })}
						onClick={() => {
							closeModal();
						}}
						href="events">
						<Icon
							name="stage"
							className="link-icon" />
						<strong>Etkinlikler</strong>
					</Link>
				}
				{/* <Link
					navLink
					className={classNames('mobilenav-link', { inactive: disableNavLinks })}
					onClick={() => {
						closeModal();
					}}
					href="home">
					<Icon
						name="menu"
						className="link-icon" />
					<strong>Menü</strong>
				</Link> */}
				{/* <Link
					navLink
					exact={false}
					className={classNames('mobilenav-link', { inactive: disableNavLinks })}
					onClick={() => {
						closeModal();
					}}
					href="superMuzikFest">
					<Icon
						name="party"
						className="link-icon" />
					<strong>Süper Müzik Fest</strong>
				</Link> */}
				<Link
					navLink
					exact={false}
					className={classNames('mobilenav-link', { inactive: disableNavLinks })}
					onClick={() => {
						closeModal();
					}}
					href="ifPayInstructions">
					<Icon
						name="help"
						className="link-icon" />
					<strong>S.S.S.</strong>
				</Link>
				<button
					type="button"
					onClick={() => {
						toggleModal(loggedIn ? 'mobileMenu' : 'auth');
					}}
					className={classNames('mobilenav-link', { active: accountItemActive })}>
					<Icon
						name="user"
						className="link-icon" />
					<strong>Hesabım</strong>
				</button>
			</div>
		</nav>
	)
}

export default MobileNav;